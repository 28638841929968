import * as THREE from 'three';
import OrbitControls from 'orbit-controls-es6';
import imagesLoaded from 'imagesloaded';
import FontFaceObserver from 'fontfaceobserver';
import Scroll from './scroll';


// Fragment controls color
// Be carefull with many computations regarding performance - gets calculated for each pixel
import fragment from "./shaders/fragment.glsl";
// Vertex controls position, shape and geometry
// You can usually get away with computations without affecting performance
import vertex from "./shaders/vertex.glsl";


import ocean from "../images/ocean-unsplash-02.jpg";
import portrait from "../images/ThomasC.jpg";


export default class Sketch {
	constructor (options) {
		this.time = 0;
		
		this.container = options.dom;
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		
		this.scene = new THREE.Scene();
		
		this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 100, 2000);
		this.camera.position.z = 600;
		this.camera.fov = 2 * Math.atan((this.height / 2) / 600) * (180 / Math.PI); // Calculates the field of view to match a width of 100 with 100 px in the DOM
		
		this.renderer = new THREE.WebGLRenderer({
			alpha: true,
			antialias: true,
		});
		
		this.container.appendChild(this.renderer.domElement);
		
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);

		this.images = [...document.querySelectorAll('img')];

		// Preload Open Sans font
    const preloadFontOpenSans = new Promise((resolve) => {
      new FontFaceObserver('Open Sans').load().then(() => {
        resolve();
      });
    });
    // Preload Playfair Display font
    const preloadFontPlayfairDisplay = new Promise((resolve) => {
      new FontFaceObserver('Playfair Display').load().then(() => {
        resolve();
      });
    });
    // Preload images
    const preloadImages = new Promise((resolve, reject) => {
        imagesLoaded(document.querySelectorAll('img'), {
        	background: true
        }, resolve);
    });

    let allDone = [preloadFontOpenSans,preloadFontPlayfairDisplay,preloadImages];

    this.currentScroll = 0;

    Promise.all(allDone).then(() => {
    	this.scroll = new Scroll();

			this.addImages();
			this.setPosition();
			// this.addObjects();
			this.resize();
			this.setupResize();
			this.render();

			// window.addEventListener('scroll', () => {
			// 	this.currentScroll = window.scrollY;
			// 	this.setPosition();
			// });
    });
	}

	setupResize () {
		window.addEventListener('resize', this.resize.bind(this));
	}

	resize () {
		this.width = this.container.offsetWidth;
		this.height = this.container.offsetHeight;
		this.renderer.setSize(this.width, this.height);
		this.camera.aspect = this.width / this.height;
		this.camera.updateProjectionMatrix();
	}

	addImages () {
		this.imageStore = this.images.map((image) => {
			let bounds = image.getBoundingClientRect();
			let geometry = new THREE.PlaneBufferGeometry(bounds.width,bounds.height, 1,1);
			let texture = new THREE.Texture(image);
			texture.needsUpdate = true;
			let material = new THREE.MeshBasicMaterial({
				map: texture,
			});
			let mesh = new THREE.Mesh(geometry, material);

			this.scene.add(mesh);

			return {
				image: image,
				mesh: mesh,
				width: bounds.width,
				height: bounds.height,
				top: bounds.top,
				left: bounds.left
			}
		});
	}

	setPosition () {
		this.imageStore.forEach((object) => {
			// Because DOM coordinate system is from top left but THREE coordinate system is from center center of the viewport we need to do some extra math
			object.mesh.position.y = this.currentScroll -object.top + (this.height / 2) - (object.height / 2);
			object.mesh.position.x = object.left - (this.width / 2) + (object.width / 2);
		});
	}

	addObjects () {
		// Adding multiple and different kinds of objects and shaders to the void
		
		// Adding square
		this.squareGeometry = new THREE.PlaneBufferGeometry(200,400 , 10,10);
		// Higher numbers makes object larger    =============^===^
		// Higher numbers gives more detail    ========================^==^
		// Higher numbers also means more calculations
		this.squareMaterial = new THREE.MeshNormalMaterial();
		this.squareMaterial = new THREE.ShaderMaterial({
			fragmentShader: fragment,
			side: THREE.DoubleSide,
			uniforms: {
				oceanTexture: {
					value: new THREE.TextureLoader().load(ocean)
				},
				time: {
					value: 0
				},
			},
			vertexShader: vertex,
			wireframe: true
		});
		this.squareMesh = new THREE.Mesh(this.squareGeometry, this.squareMaterial);
		this.scene.add(this.squareMesh);
	}

	render () {
		this.time += 0.05;

		this.scroll.render();
    this.currentScroll = this.scroll.scrollToRender;
    this.setPosition();
		
		// Adding square
		// this.squareMesh.rotation.x = this.time / 2000;
		// this.squareMesh.rotation.y = this.time / 1000;
		// this.squareMaterial.uniforms.time.value = this.time; // Let's you use "time" in the vertex shader

		this.renderer.render(this.scene, this.camera);

		window.requestAnimationFrame(this.render.bind(this));
	}
}
new Sketch({
	dom: document.getElementById('container')
});